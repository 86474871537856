import Header from "../components/Header";
import Footer from "../components/Footer";

import styles from "./About.module.scss";

export default function About() {
    const images = [
        "/images/about_carousel_1.jpg",
        "/images/about_carousel_2.jpg",
        "/images/about_carousel_3.jpg",
        "/images/about_carousel_4.jpg",
        "/images/about_carousel_5.jpg",
        "/images/about_carousel_6.jpg",
        "/images/about_carousel_7.jpg",
        "/images/about_carousel_8.jpg",
        "/images/about_carousel_9.jpg",
        "/images/about_carousel_10.jpg",
    ];

    return (
        <>
            <Header />
            <div className="main-container">
                <h1 className={styles["contact-header"]}>What we do.</h1>
                <div className={styles["about-sub"]}>
                    Want to know more about us and what we do
                </div>
                <div className={styles["about-outer"]}>
                    <div className={styles["about-image-container"]}>
                        <img
                            src="../Images/story_image.jpg"
                            alt="Vincent and Paul of VP Customs holding RC cars"
                            className={styles["about-image"]}
                        />
                    </div>
                    <div className={styles["about-text-container"]}>
                        <div className={styles["about-text-header"]}>Our Story</div>
                        <div
                            className={`${styles["about-text"]} ${styles["about-text-1"]}`}
                        >
                            Hi, we are <strong>Vincent and Paul</strong>, two car enthusiasts
                            in our early twenties from southern Germany.
                            <br />
                            During a long drive we thought it would be a good idea to start a
                            business together so we brainstormed how we could combine our
                            skills and knowledge from different areas synergetically. Vincent
                            works as a dental technician and therefore has lots of experience
                            in <strong>manufacturing</strong> - especially small and detailed
                            parts. Additionally he has years of experience with{" "}
                            <strong>building RC cars</strong>, as it has been his hobby since
                            he was 13. Paul is a <strong>business informatics student</strong>{" "}
                            and has experience with programming and{" "}
                            <strong>digital business</strong> from his studies and several
                            other personal projects.
                            <br />
                            <br />
                            From our shared love for cars the idea to build{" "}
                            <strong>perfect replicas</strong> of our dream cars in a smaller
                            scale arose. During the research of this topic, we found that
                            there are basically <strong>no options</strong> to buy custom RC
                            cars in <strong>high quality</strong>. <br />
                            There are either cheap toy cars or expensive professional cars,
                            that require a lot of time and work as you have to source, build,
                            paint, decorate, set up, etc. those cars all by yourself. <br />
                            Those factors resulted in our <strong>mission</strong>:{" "}
                        </div>
                    </div>
                </div>
                <div
                    className={`${styles["about-outer"]} ${styles["about-outer-mid"]}`}
                >
                    <div className={styles["about-image-container"]}>
                        <img
                            src="../Images/mission_image.jpg"
                            alt="a silver toyota supra RC car"
                            className={styles["about-image"]}
                        />
                    </div>
                    <div className={styles["about-text-container"]}>
                        <div className={styles["about-text-header"]}>Our Mission</div>
                        <div
                            className={`${styles["about-text"]} ${styles["about-text-2"]}`}
                        >
                            We strive to make RC racing, drifting and tuning easily accessible
                            to anyone while keeping our products as <strong>close</strong> to
                            real cars as possible.
                            <br />
                            All our cars are <strong>hand-built</strong>, offer an authentic
                            driving experience, super realistic looks and are{" "}
                            <strong>100% built and customized</strong> for you. They will
                            arrive at your door ready-run and due to their ease of use, you
                            can just focus on the fun part - <strong>driving</strong>.
                        </div>
                    </div>
                </div>
                <div
                    className={`${styles["about-outer"]} ${styles["about-outer-bottom"]}`}
                >
                    <div
                        className={styles["about-image-container"]}
                        id="about-image-container-bottom"
                    >
                        <img
                            src="../Images/services_image.jpg"
                            alt="close-up of a chassis"
                            className={styles["about-image"]}
                        />
                    </div>
                    <div className={styles["about-text-container"]}>
                        <div className={styles["about-text-header"]}>Our Services</div>
                        <div
                            className={`${styles["about-text"]} ${styles["about-text-3"]}`}
                        >
                            Building your own custom RC car that matches your own car, your
                            dream car or any other car is <strong>difficult task</strong> for
                            several reasons: <br />
                            <br />
                            1. You need to find the <strong>right parts</strong>, which will
                            take a lot of <strong>research</strong> as there are a lot of
                            parts you need. Just to name a few, you need: A chassis, a body,
                            suspension, remote control, motor, electronic speed controller,
                            batteries, charger, wheels, rubber, body, paint, lights and much
                            more. <br />
                            <br />
                            2. You need to know which part matches which. <br /> You can't use
                            every engine with every ESC, you need different batteries for
                            different types of motors, you need to make sure you can connect
                            the lights and sound and much more. 3. You need{" "}
                            <strong>experience</strong> with painting, building, decorating,
                            set-up and more. <br /> The assembly might be possible for
                            everyone with some skills with common tools but as soon as it
                            comes to painting or electrical things, experience is crucial so
                            you don't have to do the same steps several times, adding to the
                            cost every time. 4. <strong>Time</strong> is also a crucial
                            factor. It takes lots of time to gain all the{" "}
                            <strong>required knowledge</strong>, it takes time to find all the
                            perfect parts and finally it takes the most time to be able to{" "}
                            <strong>build everything</strong>.<br />
                            <br />
                            5. Last but not least: <strong>money</strong> - when getting all
                            the required parts not even factoring in the time, building a very
                            good-looking and performant RC car is{" "}
                            <strong>really expensive</strong>. <br /> A carbon fiber chassis
                            alone can cost 500€ or more and there are just so{" "}
                            <strong>many parts</strong> you need which all cost money that
                            adds up really quickly.
                            <br />
                            <br />
                            Our service is basically to{" "}
                            <strong>fix every single one of those problems</strong> for you.{" "}
                            <br />
                            <br /> You just tell us <strong>what you want</strong> and we'll
                            custom-build the perfect car for you so you don't have to worry
                            about any of the aspects above. <br /> We'll deliver the car to
                            you <strong>ready-to-run</strong>, so you can get right to the{" "}
                            <strong>action</strong>. All this at a considerably{" "}
                            <strong>lower price</strong> than if you would pay for all the
                            parts yourself and of course all cars are built by someone, who
                            has already assembled, painted, decorated, set up, etc. countless
                            cars and will therefore create the{" "}
                            <strong>best possible results</strong> for you.
                        </div>
                    </div>
                </div>
                <div className={styles["about-gallery-outer"]}>
                    <div className={styles["about-gallery"]}>
                        <hr className={styles["top-hr"]} />
                        <hr className={styles["bottom-hr"]} />
                        <div className={styles["gallery-header"]}>Our Gallery.</div>
                        <div className={styles["gallery-description"]}>
                            Below you’ll find a collection of photos we took to give you some
                            more <strong>insights</strong> of what we do and how we do it. You
                            can find images of the production process, the development
                            process, images of partially built cars or fully built cars and
                            everything in between. Just have a look.
                        </div>
                        <div className={styles["gallery-container"]}>
                            {images.map((src, index) => (
                                <img
                                    key={index}
                                    src={src}
                                    alt={`Slide ${index + 1}`}
                                    className={styles["gallery-image"]}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
