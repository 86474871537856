import Header from "../components/Header";
import Footer from "../components/Footer";

import styles from "./Legal.module.scss";

export default function Privacy() {
    return (
        <>
        <Header />
        <div className="main-container">
            <h1 className={styles["legal-header"]}>
                Privacy Policy.
            </h1>
            <div className={styles["last-updated"]}>
                Last updated on April 15th 2022
            </div>
            <div className={styles["topic-container"]}>

            </div>
        </div>
        <Footer />
        </>
    )
}
