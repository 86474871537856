import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function Footer() {
    const footerEmailRegEx =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    const [emailInput, setEmailInput] = useState("");
    const [emailInfo, setEmailInfo] = useState({
        message: "Subscribe to our newsletter",
        style: "default",
    });

    const handleInputChange = (event) => {
        setEmailInput(event.target.value);
    };

    const handleEmailConfirmation = (event) => {
        console.log(emailInput);
        if (emailInput < 1) {
            event.preventDefault();
            setEmailInfo({
                message: "Please enter an email address",
                style: "error",
            });
        } else if (!footerEmailRegEx.test(emailInput)) {
            event.preventDefault();
            setEmailInfo({
                message: "That's not an email address :(",
                style: "error",
            });
        } else {
            // Use DB Logic here
            setEmailInfo({
                message: "Email address added successfully",
                style: "success",
            });
        }
    };

    return (
        <>
            <div className={styles["footer-section"]}>
                <a className={styles["back-to-top"]} href="#top">
                    <i class={`${styles["footer-arrow"]} lni lni-chevron-up`}></i>
                </a>
                <footer className={styles.footer}>
                    <span className={styles["footer-logo"]}>
                        <Link to="/home">
                            <img
                                className={styles["logo-image"]}
                                src="../Images/Logo_white.png"
                                alt="VP Customs logo"
                            />
                        </Link>
                    </span>
                    <div className={styles["footer-nav"]}>
                        <span className={styles["footer-nav-item"]}>
                            <Link to="/about">
                                <span className={styles["footer-nav-link"]} id={styles.active}>
                                    About
                                </span>
                            </Link>
                        </span>
                        <span className={styles["footer-nav-item"]}>
                            <Link to="/products">
                                <span className={styles["footer-nav-link"]}>Products</span>
                            </Link>
                        </span>
                        <span className={styles["footer-nav-item"]}>
                            <Link to="/contact">
                                <span className={styles["footer-nav-link"]}>Contact</span>
                            </Link>
                        </span>
                        <span className={styles["footer-nav-item"]}>
                            <Link to="/faq">
                                <span className={styles["footer-nav-link"]}>FAQ</span>
                            </Link>
                        </span>
                    </div>
                    <div className={styles["footer-text"]}>
                        Producing high quality, hand built RC cars based on your dreams.
                        Just tell us what you want - we’ll take care of the rest so you can
                        focus on driving.
                    </div>
                    <div className={styles["social-section"]}>
                        <div className={styles["social-media-section"]}>
                            <a
                                href="https://www.instagram.com/vpcustomsrc/"
                                className={styles["social-item"]}
                            >
                                <i className="lni lni-instagram"></i>
                            </a>
                            <a
                                href="https://www.tiktok.com/@vpcustomsrc"
                                className={styles["social-item"]}
                            >
                                <i className="lni lni-tiktok"></i>
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCx3AxpWQ_foi_vP2On-DHlQ"
                                className={styles["social-item"]}
                            >
                                <i className="lni lni-youtube"></i>
                            </a>
                        </div>
                        <div className={styles["newsletter-section"]}>
                            <div className={styles.subscribe}>{emailInfo.message}</div>
                            <form className="form-group footer-form-group">
                                <input
                                    onChange={handleInputChange}
                                    maxLength="250"
                                    type="text"
                                    className="form-field footer-form-field"
                                    placeholder="Email"
                                    name="email"
                                    id="email"
                                />
                                <label htmlFor="name" className="form-label footer-form-label">
                                    Email
                                </label>
                                <button
                                    onClick={handleEmailConfirmation}
                                    type="submit"
                                    className={`form-confirmation-button ${styles["footer-confirmation-button"]}`}
                                >
                                    Confirm
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className={styles["legal-section"]}>
                        <Link to="/privacy">
                            <span className={styles["legal-item"]}>Privacy Policy</span>
                        </Link>
                        <Link to="/terms">
                            <span className={styles["legal-item"]}>Terms & Conditions</span>
                        </Link>
                        <Link to="/disclaimer">
                            <span className={styles["legal-item"]}>Disclaimer</span>
                        </Link>
                    </div>
                </footer>
            </div>
        </>
    );
}
