import Header from "../components/Header";
import Footer from "../components/Footer";

import { Link } from "react-router-dom";

import styles from "./NoPage.module.scss";

export default function NoPage() {
    return (
        <>
        <Header />
            <div className="main-container">
                <h1 className={styles["legal-header"]}>
                    Page not Found.
                </h1>
                <div className={styles["status-main-content"]}>
                    <div className={styles["status-content"]}>
                        This page does not exist.<br/>
                        Check if the <strong>URL</strong> you have entered is correct, 
                        or try navigating to the page using <strong>links and buttons</strong>.<br />
                        If you like you can:
                    </div>
                    <div className={styles["status-buttons"]}>
                        <Link to="/home">
                            <div className="standard-button drawing-button">
                                Go to homepage
                            </div>
                        </Link>
                        <div className={styles["alternative"]}>
                            or
                        </div>
                        <Link to="/products">
                            <div className="standard-button drawing-button">
                                Continue shopping
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        <Footer />
        </>
    )

}
