import Header from "../components/Header";
import Footer from "../components/Footer";

import { Link } from "react-router-dom";

import styles from "./Contact.module.scss";
import { useState } from "react";

export default function Contact() {
    const [emailInput, setEmailInput] = useState("");
    const [topicInput, setTopicInput] = useState("");
    const [textInput, setTextInput] = useState("");

    const [emailError, setEmailError] = useState("");
    const [topicError, setTopicError] = useState("");
    const [textError, setTextError] = useState("");

    const [success, setSuccess] = useState("");

    const handleEmailInput = (event) => {
        setEmailInput(event.target.value);
    };

    const handleTopicInput = (event) => {
        setTopicInput(event.target.value);
    };

    const handleTextlInput = (event) => {
        setTextInput(event.target.value);
    };

    const handleConfirmation = () => {
        let inputValid = true;
        const emailRegEx =
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

        if (emailInput.length === 0) {
            setEmailError("Please enter your email address");
            inputValid = false;
        } else if (!emailRegEx.test(emailInput)) {
            setEmailError("That's not an email address :(");
            inputValid = false;
        } else {
            setEmailError("");
        }

        if (topicInput.length === 0) {
            setTopicError("Please enter a topic");
            inputValid = false;
        } else {
            setTopicError("");
        }

        if (textInput.length === 0) {
            setTextError("Please enter some text");
            inputValid = false;
        } else {
            setTextError("");
        }

        if (inputValid) {
            // Logic for saving to DB
            // Logic for informing user of success
            setSuccess(
                "Thanks for contacting us, we'll reply as quickly as possible.",
            );
        }
    };
    return (
        <>
            <Header />
            <div className="main-container">
                <h1 className={styles["contact-header"]}>Get in touch.</h1>
                <div
                    className={`${styles["contact-sub"]} ${success.length !== 0 ? styles["success"] : ""}`}
                >
                    {success.length === 0
                        ? "Ask your questions, connect with us or just say 'hi'."
                        : success}
                </div>
                <div className={styles["contact-form-outer"]}>
                    <form className={styles["contact-form"]}>
                        <div className={styles["contact-group"]}>
                            <div className={styles["contact-problem"]}>{emailError}</div>
                            <div className="form-group form-group-v1 contact-input">
                                <input
                                    onChange={handleEmailInput}
                                    maxLength="250"
                                    type="text"
                                    className="form-field form-field-v1"
                                    placeholder="email"
                                    name="email"
                                    id="email"
                                />
                                <label htmlFor="name" className="form-label form-label-v1">
                                    Your Email Address
                                </label>
                            </div>
                        </div>
                        <div className={styles["contact-group"]}>
                            <div className={styles["contact-problem"]}>{topicError}</div>
                            <div className="form-group form-group-v1 contact-input topic-input">
                                <input
                                    onChange={handleTopicInput}
                                    maxLength="250"
                                    type="text"
                                    className="form-field form-field-v1"
                                    placeholder="topic"
                                    name="topic"
                                    id="topic"
                                />
                                <label htmlFor="name" className="form-label form-label-v1">
                                    Your Topic
                                </label>
                            </div>
                        </div>
                        <div className={styles["contact-group"]}>
                            <div className={styles["contact-problem"]}>{textError}</div>
                            <div className={styles["contact-textarea-outer"]}>
                                <textarea
                                    onChange={handleTextlInput}
                                    className={styles["contact-textarea"]}
                                    id="text"
                                    name="text"
                                    cols="30"
                                    rows="10"
                                    placeholder="Your text"
                                ></textarea>
                            </div>
                        </div>
                        <div
                            onClick={handleConfirmation}
                            className="standard-button drawing-button"
                        >
                            Contact us
                        </div>
                        <div className={styles["faq-section"]}>
                            Click{" "}
                            <Link to="/faq">
                                <span className={styles["faq-link-bottom"]}>here</span>
                            </Link>{" "}
                            to check if your question has been asked before.
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
}
