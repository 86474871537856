import Header from "../components/Header";
import Footer from "../components/Footer";

import { Link } from "react-router-dom";

import styles from "./Faq.module.scss";
import { useState } from "react";

export default function Faq() {
    // Init question data
    const buildQuestionData = () => {
        const data = fetchQuestionData();
        return data.map((item) => ({
            ...item,
            open: false,
            visible: true,
        }));
    };

    // Get question data initially
    const fetchQuestionData = () => {
        let data = [
            {
                id: 1,
                question: "Frage 1",
                answer: "Antwort  1",
            },
            {
                id: 2,
                question: "Question 2",
                answer: "Answer 2",
            },
        ];
        return data;
    };

    // Set questionData
    const [questionData, setQuestionData] = useState(() => buildQuestionData());

    const handleOpenQuestion = (question) => {
        setQuestionData((prevData) =>
            prevData.map((item) =>
                item.id === question.id ? { ...item, open: !item.open } : item,
            ),
        );
    };

    const handleSearchInput = (event) => {
        let value = event.target.value.toLowerCase();
        setQuestionData((prevData) =>
            prevData.map((item) => {
                if (value.length > 0) {
                    const matchesSearch =
                        item.question.toLowerCase().includes(value) ||
                        item.answer.toLowerCase().includes(value);

                    return { ...item, visible: matchesSearch };
                } else {
                    return { ...item, visible: true };
                }
            }),
        );
    };

    return (
        <>
            <Header />
            <div className="main-container">
                <h1 className={styles["faq-header"]}>FAQ.</h1>
                <div className={styles["faq-sub"]}>
                    Has your question been asked before?
                </div>
                <div className={styles["faq-wrapper"]}>
                    <div className={styles["search-section"]}>
                        <div className={styles["search-group"]}>
                            <div className={styles["search-problem"]}></div>
                            <div className="form-group form-group-v1 contact-input">
                                <input
                                    maxlength="250"
                                    onChange={handleSearchInput}
                                    type="text"
                                    className="form-field form-field-v1"
                                    placeholder="search"
                                    name="search"
                                    id="search"
                                />
                                <label htmlFor="search" className="form-label form-label-v1">
                                    Type what you are looking for...
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={styles["faq-section"]}>
                        {questionData
                            .filter((item) => item.visible)
                            .map((item) => (
                                <div className={styles["faq-container"]}>
                                    <div className={styles["faq-question-section"]}>
                                        <div className={styles["faq-question-text"]}>
                                            {item.question}
                                        </div>
                                        <div
                                            onClick={() => handleOpenQuestion(item)}
                                            className={styles["faq-extend"]}
                                        >
                                            <i
                                                className={`lni lni-chevron-down ${styles["faq-arrow"]}`}
                                            ></i>
                                        </div>
                                    </div>
                                    <div
                                        className={`${styles["faq-answer-section"]} ${item.open ? styles["active"] : ""}`}
                                    >
                                        <div className={styles["faq-answer-text"]}>
                                            {item.answer}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className={`${styles["no-results"]} ${styles["hidden"]}`}>
                        No questions / answers match your query.
                    </div>
                    <div className={styles["faq-bottom"]}>
                        <div className={styles["faq-bottom-text"]}>
                            Didn't find what you were looking for?
                        </div>
                        <Link to="/contact">
                            <div className="standard-button drawing-button">Contact Us</div>
                        </Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
