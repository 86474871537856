import Header from "../components/Header";
import Footer from "../components/Footer";

import { Link } from "react-router-dom";

import styles from "./Products.module.scss";
import { useState } from "react";

export default function Products() {
    const [isExplanationHidden, setIsExplanationHidden] = useState(true);
    const [visibleHelp, setVisibleHelp] = useState({
        help1: false,
        help2: false,
        help3: false,
        help4: false,
        help5: false,
        help6: false,
        help7: false,
        help8: false,
        help9: false,
        help10: false,
        help11: false,
        help12: false,
        help13: false,
    });

    const handleShowExplanation = () => {
        setIsExplanationHidden((prevState) => !prevState);
    };

    const handleToggle = (field) => {
        setVisibleHelp((prevFields) => ({
            ...prevFields,
            [field]: !prevFields[field],
        }));
    };

    const [selection, setSelection] = useState({
        body: "",
        motor: "",
        motorNotes: "",
        color: "",
        master: false,
        masterNotes: "",
        wheels: "",
        visual: false,
        visualNotes: "",
        handling: false,
        handlingNotes: "",
        lighting: false,
        lightingNotes: "",
        sound: false,
        soundNotes: "",
        driver: false,
        driverNotes: "",
        drift: false,
        driftNotes: "",
        setupNotes: "",
        specialRequirements: "",
        email: "",
    });

    const [price, setPrice] = useState(427);

    const handleTextInput = (event, field) => {
        setSelection((prevFields) => ({
            ...prevFields,
            [field]: event.target.value,
        }));
    };

    const handleMotorSelection = (event) => {
        let selectedMotor = event.target.value;

        setSelection((prevFields) => ({
            ...prevFields,
            "motor": selectedMotor,
        }));

        if (selectedMotor === "pro") {
            setPrice(price + 90);
        } else if (selectedMotor === "standard" && selection.motor === "pro") {
            setPrice(price - 90);
        }
    };

    const handleCheckbox = (event, field, amount) => {
        let isChecked = event.target.checked;

        setSelection((prevFields) => ({
            ...prevFields,
            [field]: isChecked,
        }));

        if (isChecked) {
            setPrice(price + amount);
        } else {
            setPrice(price - amount);
        }
    };

    const [bodyError, setBodyError] = useState(false);
    const [motorError, setMotorError] = useState(false);
    const [colorError, setColorError] = useState(false);
    const [wheelsError, setWheelsError] = useState(false);
    const [emailError, setEmailError] = useState("");

    const handleConfirm = () => {
        let inputValid = true;

        if (!selection["body"]) {
            setBodyError(true);
            inputValid = false;
        } else {
            setBodyError(false);
        }
        if (!selection["motor"]) {
            setMotorError(true);
            inputValid = false;
        } else {
            setMotorError(false);
        }
        if (!selection["color"]) {
            setColorError(true);
            inputValid = false;
        } else {
            setColorError(false);
        }
        if (!selection["wheels"]) {
            setWheelsError(true);
            inputValid = false;
        } else {
            setWheelsError(false);
        }

        const emailRegEx =
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

        if (selection["email"].length === 0) {
            setEmailError("Please enter your email address");
            inputValid = false;
        } else if (!emailRegEx.test(selection["email"])) {
            setEmailError("That's not an email address :(");
            inputValid = false;
        } else {
            setEmailError("");
        }

        if (inputValid) {
            // Create DB object with additional price
            let priceString = price + ",99";
            console.log(priceString);
        }
    };

    return (
        <>
            <Header />
            <div className="main-container">
                <h1 className={styles["shopping-cart-header"]}>Custom Shop.</h1>
                <div className={styles["custom-outer"]}>
                    <div className={styles["explanation"]}>
                        <div
                            onClick={handleShowExplanation}
                            className="standard-button drawing-button"
                            id="how-it-works"
                        >
                            Read how it works
                        </div>
                        <div
                            className={`${styles["explanation-text"]} ${isExplanationHidden ? styles["hidden"] : ""}`}
                        >
                            Click on the <strong>questionmarks</strong> for short explanations
                            about required inputs of a field or certain packages. <br />
                            We carefully <strong>review</strong> every request so please only
                            send one if you actually want to order a car. <br />
                            <br />
                            After receiving your request, we'll review it and send you a list
                            of all the features you choose and a precise price calulcation via
                            email (don't forget to check your spam folder). <br />
                            <br />
                            You then have the chance to make changes to your order or finalize
                            it. After we've received your payment, we'll start building your
                            car. If you have any questions, you can always{" "}
                            <a
                                href="../Contact_Page/contact_page.php"
                                className={styles["standard-page-link"]}
                            >
                                contact us
                            </a>
                        </div>
                        <div className={styles["starting-price"]}>
                            Starting at: <span className={styles["price-main"]}>427,99€</span>
                        </div>
                    </div>
                    <form
                        action="./custom_order_add_to_db.php"
                        method="POST"
                        className={styles["specification-form"]}
                    >
                        <div className={styles["explanation-header"]}>Spec your car.</div>
                        <div className={styles["choice"]}>
                            <div className={styles["choice-top"]}>
                                <div className={`form-group ${styles["spec-form-outer"]}`}>
                                    <input
                                        onChange={(event) => handleTextInput(event, "body")}
                                        maxlength="250"
                                        type="text"
                                        className="form-field"
                                        placeholder="Body"
                                        name="body"
                                        id="body"
                                    />
                                    <label for="name" className="form-label">
                                        Enter car body
                                    </label>
                                </div>
                                <div
                                    onClick={() => handleToggle("help1")}
                                    className={styles["choice-support"]}
                                >
                                    <i
                                        className={`lni lni-question-circle ${styles["question-circle"]}`}
                                    ></i>
                                </div>
                            </div>
                            <div
                                className={`${styles["choice-bottom"]} ${!visibleHelp.help1 ? styles["hidden"] : ""}`}
                            >
                                If your body is part of our standard selection, it won’t cost
                                any additional money, but for every other body we’ll charge a
                                certain additional amount, highly depending on the body you
                                choose.
                                <br />
                                <strong>Tip:</strong> Being specific with your body request is
                                great, but please keep in mind, that the more specific you are,
                                the less likely it might be for us to actually build your car.
                                <br />
                                <strong>Important:</strong> We can not buy just any car, that’s
                                why the first thing we’ll tell you after having reviewed your
                                request is if the car with the selected body can be built or not
                                and provide you with good alternatives.
                                <br />
                                In the future, we’ll try to be able to create just about any
                                car, but currently we are still limited so if we can’t build
                                your car right now, don’t give up hope, we might be able to in
                                the next months.
                            </div>
                        </div>
                        <div className={styles["choice-v2"]}>
                            <div className={styles["form-description"]}>
                                Choose your motor:
                            </div>
                            <div className={styles["options-outer"]}>
                                <div className={styles["options-top"]}>
                                    <div className={styles["options-top-1"]}>
                                        <div className={styles["option-selection"]}>
                                            <article
                                                className={`checkbox-selection ${styles["chassis-selection"]}`}
                                            >
                                                <input
                                                    onChange={handleMotorSelection}
                                                    maxlength="250"
                                                    type="radio"
                                                    value="standard"
                                                    className={styles["standard-input"]}
                                                    name="chassis"
                                                />
                                                <div>
                                                    <span className="checkbox-text">Standard Motor</span>
                                                </div>
                                            </article>
                                            <article
                                                className={`checkbox-selection ${styles["chassis-selection"]} ${styles["chassis-selection-2"]}`}
                                            >
                                                <input
                                                    onChange={handleMotorSelection}
                                                    maxlength="250"
                                                    type="radio"
                                                    value="pro"
                                                    className={styles["standard-input"]}
                                                    name="chassis"
                                                />
                                                <div>
                                                    <span className="checkbox-text">
                                                        Pro Motor (+90€)
                                                    </span>
                                                </div>
                                            </article>
                                        </div>
                                        <div
                                            onClick={() => handleToggle("help2")}
                                            className={styles["selection-help"]}
                                        >
                                            <i
                                                className={`lni lni-question-circle ${styles["question-circle"]}`}
                                            ></i>
                                        </div>
                                    </div>
                                    <div className={styles["options-top-2"]}>
                                        <div
                                            className={`form-group ${styles["spec-form-outer"]} ${styles["other-spec"]} ${styles["chassis-spec-other"]}`}
                                        >
                                            <input
                                                onChange={(event) =>
                                                    handleTextInput(event, "motorNotes")
                                                }
                                                maxlength="1500"
                                                type="text"
                                                className="form-field"
                                                placeholder="Chassis notes"
                                                name="chassis-notes"
                                                id="chassis-notes"
                                            />
                                            <label for="name" className="form-label">
                                                Notes on motor
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`${styles["choice-bottom"]} ${!visibleHelp.help2 ? styles["hidden"] : ""}`}
                                >
                                    <strong>STANDARD:</strong> Our standard motor is a reliable
                                    and finely controllable type 540 brushed motor that reacts to
                                    inputs extremely precisely. This makes driving at low speeds
                                    really enjoyable, but of course the motor is also made for
                                    speed. <br />
                                    With the standard motor, your car can reach a top speed of
                                    approximately 30 km/h.
                                    <br />
                                    <br />
                                    <strong>PRO:</strong> The pro motor is not just a stronger
                                    motor, but this also includes an upgraded electronic speed
                                    control unit, capable of running stronger batteries and of
                                    course lots of important adjustments to the chassis are made.{" "}
                                    <br />
                                    The motor itself is a brushless motor that has a higher energy
                                    efficiency while still staying really reliable. <br />
                                    This motor is able to accelerate your car to approximately 50
                                    km/h, potentially even more.
                                    <br />
                                    <br />
                                    <strong>Custom:</strong> Faster is always possible. Just tell
                                    us how fast you want to go and we'll make your car quicker.
                                    But please keep in mind that beyond 50km/h the car might
                                    become less reliable.
                                </div>
                            </div>
                        </div>
                        <div className={styles["choice"]}>
                            <div className={styles["form-description"]}>Choose a color:</div>
                            <div
                                className={`${styles["choice-top"]} ${styles["color-choice"]}`}
                            >
                                <div className={`form-group ${styles["spec-form-outer"]}`}>
                                    <input
                                        onChange={(event) => handleTextInput(event, "color")}
                                        maxlength="1500"
                                        type="text"
                                        className="form-field"
                                        placeholder="Color"
                                        name="color"
                                        id="color"
                                    />
                                    <label for="name" className="form-label">
                                        Color description / hexcode
                                    </label>
                                </div>
                                <div
                                    onClick={() => handleToggle("help3")}
                                    className={styles["choice-support"]}
                                >
                                    <i
                                        className={`lni lni-question-circle ${styles["question-circle"]}`}
                                    ></i>
                                </div>
                            </div>
                            <div
                                className={`${styles["choice-bottom"]} ${!visibleHelp.help3 ? styles["hidden"] : ""}`}
                            >
                                Chose the right color for your car according to your
                                imagination. If the color is part of the standard selection for
                                the chassis, we won’t charge anything extra. <br />
                                We have a large variety of colors available, just tell us which
                                color you want, and we’ll find a color that’s as close to it as
                                possible. Our colors can of course all be made brighter or
                                darker, but we are currently still working on a way to mix our
                                own colors to match your request even better. <br />
                                Before production, we will of course send you a sample of the
                                color.
                                <br />
                                <br />
                                To get a more advanced paint job than a single color body,
                                please use our master paint job.
                            </div>
                            <div className={styles["feature master-paint"]}>
                                <div className={styles["feature-top"]}>
                                    <div className={styles["feature-main"]}>
                                        <div className={styles["feature-checkbox"]}>
                                            <label className="checkbox path">
                                                <input
                                                    onClick={(event) =>
                                                        handleCheckbox(event, "master", 40)
                                                    }
                                                    maxlength="250"
                                                    type="checkbox"
                                                    name="master-paintjob"
                                                />
                                                <svg viewBox="0 0 21 21">
                                                    <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                                                </svg>
                                            </label>
                                        </div>
                                        <div className={styles["feature-text"]}>
                                            Master Custom Paintjob (+40€)
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => handleToggle("help4")}
                                        className={`${styles["selection-help"]} ${styles["pacakge-help"]}`}
                                    >
                                        <i
                                            className={`lni lni-question-circle ${styles["question-circle"]}`}
                                        ></i>
                                    </div>
                                </div>
                                <div
                                    className={`form-group ${styles["spec-form-outer"]} ${styles["other-spec"]}`}
                                >
                                    <input
                                        onChange={(event) => handleTextInput(event, "masterNotes")}
                                        maxlength="1500"
                                        type="text"
                                        className="form-field"
                                        placeholder="Master Paintjob Notes"
                                        name="master-paintjob-notes"
                                        id="master-paintjob-notes"
                                    />
                                    <label for="name" className="form-label">
                                        Notes on master paint
                                    </label>
                                </div>
                                <div
                                    className={`${styles["choice-bottom"]} ${styles["feature-help"]} ${!visibleHelp.help4 ? styles["hidden"] : ""}`}
                                >
                                    Select this option to get a more advanced paint job such as
                                    metallic paint, paint with flake, multicolor paint, multi
                                    layered paint, flip-flop, etc. <br />
                                    This also has to be selected if you want additional decals,
                                    improved panel-gaps (blackened), mirrored side view mirrors,
                                    extra chrome parts, etc.
                                    <br />
                                    In general, this is the option that will provide you with the
                                    most detail possible. <br />
                                    When selecting both this and the visual package, the cost will
                                    not necessarily add up. Only additional features that one
                                    package does not include will be added.
                                </div>
                            </div>
                        </div>
                        <div className={styles["choice"]}>
                            <div className={styles["form-description"]}>
                                Choose your wheels:
                            </div>
                            <div
                                className={`${styles["choice-top"]} ${styles["color-choice"]}`}
                            >
                                <div className={`form-group ${styles["spec-form-outer"]}`}>
                                    <input
                                        onChange={(event) => handleTextInput(event, "wheels")}
                                        maxlength="1500"
                                        type="text"
                                        className="form-field"
                                        placeholder="Wheels"
                                        name="wheels"
                                        id="wheels"
                                    />
                                    <label for="name" className="form-label">
                                        Description of wheels / Brand / Model
                                    </label>
                                </div>
                                <div
                                    onClick={() => handleToggle("help5")}
                                    className={styles["choice-support"]}
                                >
                                    <i
                                        className={`lni lni-question-circle ${styles["question-circle"]}`}
                                    ></i>
                                </div>
                            </div>
                            <div
                                className={`${styles["choice-bottom"]} ${!visibleHelp.help5 ? styles["hidden"] : ""}`}
                            >
                                If you choose wheels from the standard selection of your body,
                                they won’t cost more.
                                <br />
                                You can just tell us which wheels you want, and we’ll source the
                                wheels that look as close to your request as possible. <br />
                                We are currently looking for a way to model all the different
                                types of wheels so that soon we might be able to provide just
                                about any wheel.
                            </div>
                        </div>
                        <div className={styles["choice"]}>
                            <div className={styles["options-outer engine-options"]}></div>
                        </div>
                        <div className={styles["packages"]}>
                            <div className={styles["form-description"]}>Choose packages:</div>
                            <div className={styles["additional-features"]}>
                                <div className={styles["feature"]}>
                                    <div className={styles["feature-top"]}>
                                        <div className={styles["feature-main"]}>
                                            <div className={styles["feature-checkbox"]}>
                                                <label className="checkbox path">
                                                    <input
                                                        onClick={(event) =>
                                                            handleCheckbox(event, "visual", 30)
                                                        }
                                                        maxlength="250"
                                                        type="checkbox"
                                                        name="visual"
                                                    />
                                                    <svg viewBox="0 0 21 21">
                                                        <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                                                    </svg>
                                                </label>
                                            </div>
                                            <div className={styles["feature-text"]}>
                                                Visual Package - Level up the looks of your car (+30€)
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleToggle("help6")}
                                            className={`${styles["selection-help"]} ${styles["pacakge-help"]}`}
                                        >
                                            <i
                                                className={`lni lni-question-circle ${styles["question-circle"]}`}
                                            ></i>
                                        </div>
                                    </div>
                                    <div
                                        className={`form-group ${styles["spec-form-outer"]} ${styles["other-spec"]}`}
                                    >
                                        <input
                                            onChange={(event) =>
                                                handleTextInput(event, "visualNotes")
                                            }
                                            maxlength="1500"
                                            type="text"
                                            className="form-field"
                                            placeholder="Visual notes"
                                            name="visual-notes"
                                            id="visual-notes"
                                        />
                                        <label for="name" className="form-label">
                                            Notes on visual package
                                        </label>
                                    </div>
                                    <div
                                        className={`${styles["choice-bottom"]} ${styles["feature-help"]} ${!visibleHelp.help6 ? styles["hidden"] : ""}`}
                                    >
                                        The visual package adds various visual enhancements to your
                                        vehicle, including: Visible brake-discs, exhaust-tips,
                                        potentially spoilers, side mirrors in different colors, vent
                                        openings (depending on chosen model) and overall enhanced
                                        paint job details.
                                        <br />
                                        The contents of the visual package may vary slightly for
                                        each body. <br />
                                        <br />
                                        Customize all the little details. Want side mirrors in a
                                        completely different color, want a certain caliper / brake
                                        disk color? No problem.
                                        <br />
                                        In general, most parts and most details are customizable.{" "}
                                        <br />
                                        Minor changes do not require the visual package to be
                                        selected, and the price may vary depending on the level of
                                        detail.
                                    </div>
                                </div>
                                <div className={styles["feature"]}>
                                    <div className={styles["feature-top"]}>
                                        <div className={styles["feature-main"]}>
                                            <div className={styles["feature-checkbox"]}>
                                                <label className="checkbox path">
                                                    <input
                                                        onClick={(event) =>
                                                            handleCheckbox(event, "handling", 55)
                                                        }
                                                        maxlength="250"
                                                        type="checkbox"
                                                        name="handling"
                                                    />
                                                    <svg viewBox="0 0 21 21">
                                                        <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                                                    </svg>
                                                </label>
                                            </div>
                                            <div className={styles["feature-text"]}>
                                                Handling Package - Need additional control over your
                                                vehicle? (+55€)
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleToggle("help7")}
                                            className={`${styles["selection-help"]} ${styles["pacakge-help"]}`}
                                        >
                                            <i
                                                className={`lni lni-question-circle ${styles["question-circle"]}`}
                                            ></i>
                                        </div>
                                    </div>
                                    <div
                                        className={`form-group ${styles["spec-form-outer"]} ${styles["other-spec"]}`}
                                    >
                                        <input
                                            onChange={(event) =>
                                                handleTextInput(event, "handlingNotes")
                                            }
                                            maxlength="1500"
                                            type="text"
                                            className="form-field"
                                            placeholder="Handling notes"
                                            name="handling-notes"
                                            id="handling-notes"
                                        />
                                        <label for="name" className="form-label">
                                            Notes on handling package
                                        </label>
                                    </div>
                                    <div
                                        className={`${styles["choice-bottom"]} ${styles["feature-help"]} ${!visibleHelp.help7 ? styles["hidden"] : ""}`}
                                    >
                                        This package includes an improved faster servo with metal
                                        gears to make it even more durable and more agile - to react
                                        to your inputs quicker.
                                    </div>
                                </div>
                                <div className={styles["feature"]}>
                                    <div className={styles["feature-top"]}>
                                        <div className={styles["feature-main"]}>
                                            <div className={styles["feature-checkbox"]}>
                                                <label className="checkbox path">
                                                    <input
                                                        onClick={(event) =>
                                                            handleCheckbox(event, "lighting", 30)
                                                        }
                                                        maxlength="250"
                                                        type="checkbox"
                                                        name="lighting"
                                                    />
                                                    <svg viewBox="0 0 21 21">
                                                        <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                                                    </svg>
                                                </label>
                                            </div>
                                            <div className={styles["feature-text"]}>
                                                Lighting Package - Light it up! (+30€)
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleToggle("help8")}
                                            className={`${styles["selection-help"]} ${styles["pacakge-help"]}`}
                                        >
                                            <i
                                                className={`lni lni-question-circle ${styles["question-circle"]}`}
                                            ></i>
                                        </div>
                                    </div>
                                    <div
                                        className={`form-group ${styles["spec-form-outer"]} ${styles["other-spec"]}`}
                                    >
                                        <input
                                            onChange={(event) =>
                                                handleTextInput(event, "lightingNotes")
                                            }
                                            maxlength="1500"
                                            type="text"
                                            className="form-field"
                                            placeholder="Lighting notes"
                                            name="lighting-notes"
                                            id="lighting-notes"
                                        />
                                        <label for="name" className="form-label">
                                            Notes on lighting package
                                        </label>
                                    </div>
                                    <div
                                        className={`${styles["choice-bottom"]} ${styles["feature-help"]} ${!visibleHelp.help8 ? styles["hidden"] : ""}`}
                                    >
                                        This package adds working head- and taillights to your car.{" "}
                                        <br />
                                        The light module is added to the chassis and is powered with
                                        the same power source as the driving mechanism. Just plug it
                                        into an empty spot on the receiver, and you’re ready to go.
                                        The light assembly may change depending on the car to fit
                                        the style of the car.
                                        <br />
                                        <br />
                                        The lights (especially headlights) are adjusted to look as
                                        realistic as possible.
                                        <br />
                                        <br />
                                        You can customize color, brightness, etc.
                                    </div>
                                </div>
                                <div className={styles["feature"]}>
                                    <div className={styles["feature-top"]}>
                                        <div className={styles["feature-main"]}>
                                            <div className={styles["feature-checkbox"]}>
                                                <label className="checkbox path">
                                                    <input
                                                        onClick={(event) =>
                                                            handleCheckbox(event, "sound", 75)
                                                        }
                                                        maxlength="250"
                                                        type="checkbox"
                                                        name="sound"
                                                    />
                                                    <svg viewBox="0 0 21 21">
                                                        <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                                                    </svg>
                                                </label>
                                            </div>
                                            <div className={styles["feature-text"]}>
                                                Sound Package - Make them hear you (+75€)
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleToggle("help9")}
                                            className={`${styles["selection-help"]} ${styles["pacakge-help"]}`}
                                        >
                                            <i
                                                className={`lni lni-question-circle ${styles["question-circle"]}`}
                                            ></i>
                                        </div>
                                    </div>
                                    <div
                                        className={`form-group ${styles["spec-form-outer"]} ${styles["other-spec"]}`}
                                    >
                                        <input
                                            onChange={(event) => handleTextInput(event, "soundNotes")}
                                            maxlength="1500"
                                            type="text"
                                            className="form-field"
                                            placeholder="Sound notes"
                                            name="sound-notes"
                                            id="sound-notes"
                                        />
                                        <label for="name" className="form-label">
                                            Notes on sound package
                                        </label>
                                    </div>
                                    <div
                                        className={`${styles["choice-bottom"]} ${styles["feature-help"]} ${!visibleHelp.help9 ? styles["hidden"] : ""}`}
                                    >
                                        Powerful, bassy sound that fits your selected vehicle.
                                        <br />
                                        The sounds change depending on how much you press the
                                        throttle. Without throttle, your car will sound like it’s
                                        idling, and will then rev according to your input. <br />
                                        If throttle is fully pressed a rev limiter will appear.{" "}
                                        <br />
                                        When releasing the throttle you’ll get that sweet turbo
                                        flutter (in case your car has a turbo).
                                        <br />
                                        When the car is ‘off’ and you press the throttle, you’ll get
                                        a startup sound.
                                        <br />
                                        Finally we'll also add a fitting horn sound you can trigger
                                        via the remote control
                                        <br />
                                        <br />
                                        Custom: Select if you want the sound to ‘shift’ after
                                        holding the throttle for a certain period or hit a rev
                                        limiter.
                                        <br />
                                        If you like you can get a different sound than the sound of
                                        your specific car - want a 2JZ in your S15? No problem! Have
                                        you heard of the ominous LS swap?
                                        <br />
                                        Additionally you can customize all the little features like
                                        how you want your turbo to sound and even what horn sound
                                        you want. (The horn sound can be anything you want, it could
                                        even be a song you like.)
                                    </div>
                                </div>
                                <div className={styles["feature"]}>
                                    <div className={styles["feature-top"]}>
                                        <div className={styles["feature-main"]}>
                                            <div className={styles["feature-checkbox"]}>
                                                <label className="checkbox path">
                                                    <input
                                                        onClick={(event) =>
                                                            handleCheckbox(event, "driver", 40)
                                                        }
                                                        maxlength="250"
                                                        type="checkbox"
                                                        name="driver"
                                                    />
                                                    <svg viewBox="0 0 21 21">
                                                        <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                                                    </svg>
                                                </label>
                                            </div>
                                            <div className={styles["feature-text"]}>
                                                Driver Package - For those who can’t get enough (+40€)
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleToggle("help10")}
                                            className={`${styles["selection-help"]} ${styles["pacakge-help"]}`}
                                        >
                                            <i
                                                className={`lni lni-question-circle ${styles["question-circle"]}`}
                                            ></i>
                                        </div>
                                    </div>
                                    <div
                                        className={`form-group ${styles["spec-form-outer"]} ${styles["other-spec"]}`}
                                    >
                                        <input
                                            onChange={(event) =>
                                                handleTextInput(event, "driverNotes")
                                            }
                                            maxlength="1500"
                                            type="text"
                                            className="form-field"
                                            placeholder="Driver notes"
                                            name="driver-notes"
                                            id="driver-notes"
                                        />
                                        <label for="name" className="form-label">
                                            Notes on driver package
                                        </label>
                                    </div>
                                    <div
                                        className={`${styles["choice-bottom"]} ${styles["feature-help"]} ${!visibleHelp.help10 ? styles["hidden"] : ""}`}
                                    >
                                        This package aims to maximize the duration of driving whilst
                                        minimizing charging times. To achieve this, the package adds
                                        an additional, compatible large battery and a fitting fast
                                        charger.
                                        <br />
                                        <br />
                                        Customize the amount of additional batteries. Want more
                                        batteries or just the charger? No problem. Let us know and
                                        we'll adjust the package.
                                    </div>
                                </div>
                                <div className={styles["feature"]}>
                                    <div className={styles["feature-top"]}>
                                        <div className={styles["feature-main"]}>
                                            <div className={styles["feature-checkbox"]}>
                                                <label className="checkbox path">
                                                    <input
                                                        onClick={(event) =>
                                                            handleCheckbox(event, "drift", 10)
                                                        }
                                                        maxlength="250"
                                                        type="checkbox"
                                                        name="drift"
                                                    />
                                                    <svg viewBox="0 0 21 21">
                                                        <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                                                    </svg>
                                                </label>
                                            </div>
                                            <div className={styles["feature-text"]}>
                                                Drift Package - Go sideways (+10€)
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleToggle("help11")}
                                            className={`${styles["selection-help"]} ${styles["pacakge-help"]}`}
                                        >
                                            <i
                                                className={`lni lni-question-circle ${styles["question-circle"]}`}
                                            ></i>
                                        </div>
                                    </div>
                                    <div
                                        className={`form-group ${styles["spec-form-outer"]} ${styles["other-spec"]}`}
                                    >
                                        <input
                                            onChange={(event) => handleTextInput(event, "driftNotes")}
                                            maxlength="1500"
                                            type="text"
                                            className="form-field"
                                            placeholder="Drift notes"
                                            name="drift-notes"
                                            id="drift-notes"
                                        />
                                        <label for="name" className="form-label">
                                            Notes on drift package
                                        </label>
                                    </div>
                                    <div
                                        className={`${styles["choice-bottom"]} ${styles["feature-help"]} ${!visibleHelp.help11 ? styles["hidden"] : ""}`}
                                    >
                                        This package includes a set of PVC drift-tires instead of
                                        the standard rubber tires and a drift-ready setup of your
                                        chassis to match the driving style. This means, lowered
                                        chassis, more camber, stiffer shocks in the rear, softer
                                        shocks in the front and more.
                                        <br />
                                        <br />
                                        Custom: If you need more tire sets, entire wheel sets to
                                        quickly switch between driving styles or have special drift
                                        requirements, let us know.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles["choice"]}>
                            <div className={styles["form-description"]}>
                                Enter specific setup requirements:
                            </div>
                            <div
                                className={`${styles["choice-top"]} ${styles["setup-choice"]}`}
                            >
                                <div
                                    className={`form-group ${styles["spec-form-outer"]} ${styles["other-spec"]}`}
                                >
                                    <input
                                        onChange={(event) => handleTextInput(event, "setupNotes")}
                                        maxlength="1500"
                                        type="text"
                                        className="form-field"
                                        placeholder="Setup notes"
                                        name="setup-notes"
                                        id="setup-notes"
                                    />
                                    <label for="name" className="form-label">
                                        Notes on setup
                                    </label>
                                </div>
                                <div
                                    onClick={() => handleToggle("help12")}
                                    className={`${styles["selection-help"]} ${styles["pacakge-help"]}`}
                                >
                                    <i
                                        className={`lni lni-question-circle ${styles["question-circle"]}`}
                                    ></i>
                                </div>
                            </div>
                            <div
                                className={`${styles["choice-bottom"]} ${styles["feature-help"]} ${!visibleHelp.help12 ? styles["hidden"] : ""}`}
                            >
                                Enter how exactly you want your chassis to be set up. From
                                damper stiffness to camber, toe and ride height, we can adjust
                                your car, so it perfectly fits your expectations. Just let us
                                know what you need.
                            </div>
                            <div className={styles["form-description"]}>
                                Enter special requirements:
                            </div>
                            <div className={styles["text-area-section"]}>
                                <textarea
                                    onChange={(event) =>
                                        handleTextInput(event, "specialRequirements")
                                    }
                                    name="special-requirements"
                                    id="special-requirements"
                                    cols="30"
                                    rows="10"
                                    className={styles["special-req-textarea"]}
                                ></textarea>
                                <div
                                    onClick={() => handleToggle("help13")}
                                    className={`${styles["selection-help"]} ${styles["pacakge-help"]} ${styles["text-area-help"]}`}
                                >
                                    <i
                                        className={`lni lni-question-circle ${styles["question-circle"]}`}
                                    ></i>
                                </div>
                            </div>
                            <div
                                className={`${styles["choice-bottom"]} ${!visibleHelp.help13 ? styles["hidden"] : ""}`}
                            >
                                This category covers just about anything, especially those
                                aspects that are not covered by any other package or selection -
                                so you might want to double-check if your request might fit any
                                other category. <br />
                                If not - this is exactly where to put it. This might include
                                open or close headlights for cars with popups, a windshield
                                wiper delete, etc. Of course, technical details can also be put
                                here.
                            </div>
                        </div>
                        <div
                            className={`${styles["form-description"]} ${styles["price-approximation"]}`}
                        >
                            <div className={styles["approximation-left"]}>
                                Estimated price
                            </div>
                            <div className={styles["approximation-right"]}>
                                <span className={styles["price-main"]}>{price},99€</span>
                            </div>
                        </div>
                        <div
                            className={`form-group ${styles["spec-form-outer"]} ${styles["email-section"]}`}
                        >
                            <input
                                onChange={(event) => handleTextInput(event, "email")}
                                maxlength="250"
                                type="email"
                                className="form-field"
                                placeholder="Email"
                                name="email"
                                id="email"
                            />
                            <label for="email" className="form-label">
                                Your email address
                            </label>
                        </div>
                        <div className={styles["button-section"]}>
                            <div className={styles["problem-section"]}>
                                <div
                                    className={`${styles["problem-text"]} ${bodyError ? styles["visible"] : ""}`}
                                >
                                    Please select a car body.
                                </div>
                                <div
                                    className={`${styles["problem-text"]} ${motorError ? styles["visible"] : ""}`}
                                >
                                    Please select a motor.
                                </div>
                                <div
                                    className={`${styles["problem-text"]} ${colorError ? styles["visible"] : ""}`}
                                >
                                    Please select a color.
                                </div>
                                <div
                                    className={`${styles["problem-text"]} ${wheelsError ? styles["visible"] : ""}`}
                                >
                                    Please select wheels.
                                </div>
                                <div
                                    className={`${styles["problem-text"]} ${emailError ? styles["visible"] : ""}`}
                                >
                                    {emailError}
                                </div>
                            </div>
                            <div
                                onClick={handleConfirm}
                                type="submit"
                                className="standard-button drawing-button"
                            >
                                Send Request
                            </div>
                        </div>
                    </form>
                    <div className={styles["questions-text"]}>
                        Still have open questions?
                        <Link to="/contact">
                            <span className={styles["contact"]}> Contact Us</span>
                        </Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
