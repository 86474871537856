import styles from "./Header.module.scss";

import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isBgActive, setIsBgActive] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    useEffect(() => {
        const handleScroll = () => {
            let offset = window.pageYOffset;
            if(offset > 50) {
                setIsBgActive(true);
            } else {
                setIsBgActive(false);
            }
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);

    return (
        <>
        <div className={`${styles["navbar-bg"]} ${isBgActive ? styles["bg-active"] : ''}`}>.</div>
        <nav className={`${styles.navbar} ${isMenuOpen ? styles.change : ''}`}>
            <NavLink to="/home">
                <span className={styles["header-logo"]}>
                    <img className={styles["logo-image"]} src="../Images/Logo_white.png" alt="VP Customs logo" />
                </span>            
            </NavLink>
            <div onClick={handleMenuToggle} className={styles["hamburger"]}>
                <div className={`${styles["line"]} ${styles["line-1"]}`}></div>
                <div className={`${styles["line"]} ${styles["line-2"]}`}></div>
                <div className={`${styles["line"]} ${styles["line-3"]}`}></div>                
            </div>
            <ul className={styles["nav-list"]}>
                <NavLink className={({ isActive }) => isActive ? styles.active : null } to="/about">    
                    <li className={styles["nav-item"]}>
                        <span className={styles["nav-link"]}>About</span>
                    </li>
                </NavLink>
                <NavLink className={({ isActive }) => isActive ? styles.active : null } to="/products">    
                    <li className={styles["nav-item"]}>
                        <span className={styles["nav-link"]}>Products</span>
                    </li>
                </NavLink>
                <NavLink className={({ isActive }) => isActive ? styles.active : null }to="/contact">    
                    <li className={styles["nav-item"]}>
                        <span className={styles["nav-link"]}>Contact</span>
                    </li>
                </NavLink>
                <NavLink className={({ isActive }) => isActive ? styles.active : null } to="/faq">    
                    <li className={styles["nav-item"]}>
                        <span className={styles["nav-link"]}>FAQ</span>
                    </li>
                </NavLink>
            </ul>
        </nav>
        </>
    )
}

