import Header from "../components/Header";
import Footer from "../components/Footer";

import { Link } from "react-router-dom";

import styles from "./Home.module.scss";
import { useEffect, useState } from "react";

export default function Home() {
    const [backgroundPosition, setBackgroundPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setBackgroundPosition(position * 0.6);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    const [activeIndex, setActiveIndex] = useState(0);

    const images = [
        "/images/custom_carousel_1.png",
        "/images/custom_carousel_2.png",
        "/images/custom_carousel_3.png",
        "/images/custom_carousel_4.png"
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % images.length)
        }, 5000);

        return () => clearInterval(intervalId);
    }, [images.length]);

    return (
        <>
            <Header />
            <div className="main-container">
                <div
                    className={styles["top-section"]}
                    style={{
                        backgroundPosition: `center ${backgroundPosition}px`,
                    }}
                >
                    <div className={styles["top-section-inner"]}>
                        <div className={styles["main-header"]}>Welcome to VP Customs.</div>
                        <div className={styles["header-small"]}>
                            Ready-to-run, custom-built, professional RC cars.
                        </div>
                        <a
                            href="../About_Page/about_page.php"
                            className="standard-button drawing-button"
                        >
                            More on what we do
                        </a>
                        <a href="#top-scroll-aim">
                            <div className={styles["arrow-section"]}>
                                <i
                                    className={`${styles["arrow-down"]} lni lni-chevron-down`}
                                ></i>
                            </div>
                        </a>
                    </div>
                </div>
                <div className={styles["product-section"]}>
                    <div id="top-scroll-aim" className={styles["top-scroll-aim"]}>
                        .
                    </div>
                    <div className={styles["product-header"]}>Our Products.</div>
                    <div className={styles["product-main"]}>
                        <div className={styles["product-main-inner"]}>
                            <div className={styles["product-main-inner-header"]}>
                                The bodies.
                            </div>
                            <a
                                href="#scroll-body"
                                className={styles["view-specs standard-page-link"]}
                            >
                                More info below
                            </a>
                            <div className={styles["product-image-container"]}>
                                <img
                                    src="../Images/homepage_body.png"
                                    alt="a VP customs body"
                                    className={`${styles["product-image"]} ${styles[" body-image"]}`}
                                ></img>
                            </div>
                            <div className={styles["product-description"]}>
                                <div id="scroll-body" className={styles["scroll-body"]}>
                                    .
                                </div>
                                <div className={styles["product-description-header"]}>
                                    Main Features.
                                </div>
                                <div className={styles["view-specs"]}>Click for more info</div>
                                <div className={styles["product-description-list"]}>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Built for you
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                Every body is built just for you. They are painted,
                                                decorated and set-up individually and by hand based on
                                                your request. This means no two bodies are the same.
                                                With the{" "}
                                                <a
                                                    href="../Custom_Order/custom_order.php"
                                                    className={styles["standard-page-link"]}
                                                >
                                                    custom shop
                                                </a>{" "}
                                                you can choose even more option.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Attention to detail
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                High amounts of details from bumper to bumper are a
                                                standard for every one of our bodies. When choosing the
                                                visual package, the level of detail of your chosen body
                                                can be enhanced even further.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Accurate proportions
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                We make sure that all body shells we are using are
                                                true-to-life when it comes to proportions.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Light-kits
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                Optional light-kits available. Headlights, Taillights,
                                                you name it.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Invisible body-mounts
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                No body-pins destroying your looks, instead we use
                                                powerful magnets to connect chassis and body.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Individual paint jobs
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                Choose between pre-selected colors or order your very
                                                own paint job with our{" "}
                                                <a
                                                    className={styles["standard-page-link"]}
                                                    href="../Custom_Order/custom_order.php"
                                                >
                                                    Custom Shop
                                                </a>{" "}
                                                to match your own car or your dream car.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles["product-main-inner"]}>
                            <div className={styles["product-main-inner-header"]}>
                                The chassis.
                            </div>
                            <a
                                href="#scroll-chassis"
                                className={styles["view-specs standard-page-link"]}
                            >
                                More info below
                            </a>
                            <div className={styles["product-image-container"]}>
                                <img
                                    src="../Images/chassis_top.png"
                                    alt="a vp customs chassis"
                                    className={`${styles["product-image"]} ${styles["chassis-image"]}`}
                                />
                            </div>
                            <div className={styles["product-description"]}>
                                <div id="scroll-chassis" className={styles["scroll-chassis"]}>
                                    .
                                </div>
                                <div className={styles["product-description-header"]}>
                                    Main Features.
                                </div>
                                <div className={styles["view-specs"]}>Click for more info</div>
                                <div className={styles["product-description-list"]}>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Powerful motors
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                Our cars are by default equipped with high-precision
                                                Type 540 electrical motors, as well as an electronic
                                                speed controller that offers maximum, digital
                                                proportional throttle control. (Top speed: 30km/h - But
                                                we also have options for higher speeds.) - All supported
                                                by powerful batteries.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Durable drivetrain
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                Our chassis are all-wheel-drive, but depending on the
                                                car are adjusted to match the feeling of
                                                rear-wheel-drive cars. Our belt-driven drivetrain stands
                                                out for low maintenance and high reliability.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Fast steering servo
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                For maximum steering capabilities, we chose to use a
                                                quick and reliable metal-geared digital proportional
                                                steering servo. The servo is protected by a durable
                                                servo-saver.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        100% ball-bearings
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                For minimal friction and best reliability, we use only
                                                ball-bearings for the drivetrain.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Set-up friendly
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                Our chassis are highly adjustable. From damper stiffness
                                                to camber, toe and ride height - just tell us what you
                                                need.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Oil-filled dampers
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                Our oil filled aluminum dampers make for a smooth ride
                                                and realistic behavior on the road.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Premium materials
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                Carbon fiber chassis ground-plate and structural
                                                elements; type 6061 aluminum (magnesium-silicone-alloy)
                                                on all parts except the suspension which uses a hardened
                                                polymer suited for impacts.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Foam-bumper
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                Protects internals from damages on impact and is easy to
                                                replace.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["product-description-item"]}>
                                        <div className={styles["product-description-item-inner"]}>
                                            <div className={styles["product-description-sub-header"]}>
                                                <span className={styles["product-number"]}>
                                                    <span className={styles["product-number-inner"]}>
                                                        Smooth driving
                                                    </span>
                                                </span>
                                            </div>
                                            <div className={styles["product-description-text"]}>
                                                Because our chassis is made from carbon fiber and all
                                                parts are aluminum nothing bends or moves making for an
                                                extremely smooth driving experience.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles["product-main-inner"]}>
                            <div className={styles["product-main-inner-header"]}>
                                Our custom shop.
                            </div>
                            <div className={styles["custom-shop-description"]}>
                                <div className={styles["custom-shop-image"]}>
                                    {images.map((src, index) => (
                                        <img
                                            key={index}
                                            src={src}
                                            alt={`Slide ${index + 1}`}
                                            className={`${styles["image-visible"]} ${activeIndex === index ? "" : styles["image-hidden"]}`}
                                        />
                                    ))}
                                </div>
                                <div className={styles["custom-shop-text"]}>
                                    <div className={styles["cs-text-inner"]}>
                                        <div className={styles["product-main-inner-sub"]}>
                                            <strong>Get your dream RC car - 100% customized.</strong>
                                        </div>
                                        The custom shop is perfect for everyone who wants to{" "}
                                        <strong>customize</strong> their car even further. Choose a
                                        custom body, a custom color, custom decals, decorations,
                                        choose the exact wheels you want, get a stronger engine, get
                                        a specific suspension setup, customize sound settings,
                                        adjust packages according to your needs and much more.{" "}
                                        <br />
                                        <br />
                                        <strong>In general</strong>: Recreate your own car to look
                                        just like the real one, recreate your dream car or any other
                                        car.
                                    </div>
                                </div>
                            </div>
                            <Link to="/products">
                                <div className="standard-button drawing-button">
                                    Go to custom shop
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={`${styles["socials-section"]}`}>
                    <div className={styles["sub-header-2"]}>Our socials.</div>
                    <div
                        className={styles["social-media-section"]}
                        id={styles["social-links"]}
                    >
                        <a
                            href="https://www.instagram.com/vpcustomsrc/"
                            className={styles["social-item"]}
                        >
                            <i className="lni lni-instagram"></i>
                        </a>
                        <a
                            href="https://www.tiktok.com/@vpcustomsrc"
                            className={styles["social-item"]}
                        >
                            <i className="lni lni-tiktok"></i>
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UCx3AxpWQ_foi_vP2On-DHlQ"
                            className={styles["social-item"]}
                        >
                            <i className="lni lni-youtube"></i>
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
